@import "fonts";

p,h1,h2,h3,h4,h5,h6,ul,li,button {
  margin: 0; padding: 0;
}

div {
  box-sizing: border-box;
}

body, html {
  height: 100%;
}
body {
  font-family: $bodyFont, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
}
