@import "colors";
@import "fonts";

.App {
  display: flex;
  width: 100%;
  min-height: 100%;
  padding-left: 264px;
  @media screen and (max-width: 1600px) {
    padding-left: 227px;
  }
  @media screen and (max-width: 1023px) {
    padding-left: 0px;
  }
  .content {
    flex: 1;
    overflow: hidden;
  }

  .top-panel {
    width: 100%;
    background-image: linear-gradient(
        -182deg,
        $jumboBgStart 83%,
        $jumboBgEnd 115%
      ),
      linear-gradient(to bottom, $jumboBg, $jumboBg);

    @media screen and (max-width: 1023px) {
      padding-top: 50px;
    }                 
    .logo {
      width: 178px;
      height: 57px;
      object-fit: contain;

      @media screen and (max-width: 1600px) {
        width: 132px;
      }
    }
  }

  .header-stats {
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    h3 {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;

      @media screen and (max-width: 1600px) {
        font-size: 13px;
      }
    }

    label {
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: italic;

      @media screen and (max-width: 1600px) {
        font-size: 17px;
      }
    }

    .image-container {
      .background-image {
        width: 487px;

        @media screen and (max-width: 1600px) {
          width: 414px;
        }
      }

      svg {
        .box1 {
          @media screen and (max-width: 1600px) {
            transform: translate(3px, 0);
          }
        }
        .box2 {
          @media screen and (max-width: 1600px) {
            transform: translate(261px, 288.5px) rotate(-180deg);
          }
        }
      }

      .dollar-icon {
        width: 40px;
        @media screen and (max-width: 1600px) {
          margin-top: 1px;
          width: 36px;
        }
        @media screen and (max-width: 1023px) {
          margin-top: 2px;
          width: 32px;
        }
        @media screen and (max-width: 600px) {
          margin-top: 3px;
          width: 30px;
        }
      }
    }

    .borrow-limit {
      padding-right: 18px;
      flex: 1;
      width: 100%;

      h3{
        margin-bottom: 42px; 
      }


    }
  }

  .menu-item-header {
    position: fixed;
    width: 100%;
    background-image: linear-gradient(
        -182deg,
        $jumboBgStart 83%,
        $jumboBgEnd 115%
      ),
      linear-gradient(to bottom, $jumboBg, $jumboBg);
    // height: 276px;

    .item-header-title {
      padding: 78px 91px 55px 132px;
      @media screen and (max-width: 1023px){
        padding: 78px 80px 55px 30px;
      }
      font-size: 38px;
    }
  }
  .risk-header {
    padding: 18px 91px 4px 132px;
    @media screen and (max-width: 1023px){
      padding: 78px 80px 4px 30px;
    }
    font-size: 34px;
  }
  .item-header-small-text {
    width: 50%;
    padding: 12px 91px 8px 132px;
    @media screen and (max-width: 1023px){
      width: calc(100% - 60px);
      padding: 12px 30px 8px 30px;
    }
    font-size: 14px;
  }
  .item-header-icon-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 0;
    a{
      padding: 10px;
    }
  }
  .item-page-content {
    flex: 1;
    width: calc(100% - 227px);
    // height: 100vh;
  }

  .faq-content-container {
    padding: 2rem 16% 0rem 12%;
    line-height: 2rem;

    overflow-y: scroll;
    // height: calc(100vh - 276px);
    .item-content-text {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.7px;
      padding-bottom: 1rem;
      padding-left: 0.75rem;

      .item-sub-text {
        padding-left: 0.75rem;
      }
      ul{
        padding: 1rem;
        li{
          padding: 5px 0;
        }
      }
    }
  }

  .risk-item-content-text {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.7px;
    padding-bottom: 1rem;
  }

  .global-stats {
    position: relative;
    .stats {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      color: $statsColor;
      // padding-left: 28px;

      h2 {
        color: $statsTitleColor;
        font-size: 15px;
        font-weight: normal;
        display: flex;
       

        @media screen and (max-width: 1600px) {
          font-size: 13px;
          padding-left: 3px;
        }
        @media screen and (max-width: 1366px) {
          font-size: 12.5px;
          padding-left: 2px;
        }
        @media screen and (max-width: 1023px) {
          font-size: 12px;
          padding-left: 1px;
        }
        @media screen and (max-width: 600px) {
          font-size: 9px;
          padding-left: 0px;
        }

        .tooltip-container {
          margin-left: 8px;

          &:hover {
            .tooltip {
              display: flex;
            }
          }
        }

        .tooltip {
          text-align: center;
          display: none;
          top: -95px;
          min-width: 103px;
          height: 78px;
          transition: all 0.2s;

          h3 {
            font-size: 25px;
            font-weight: 600;
          }
        }

        img {
          width: 17px;
        }
      }

      .value {
        font-family: $titleFont;
        font-size: 42px;

        @media screen and (max-width: 1600px) {
          font-size: 31px;
        }
        @media screen and (max-width: 1366px) {
          font-size: 30px;
        }
      }

      > * {
        flex: 1;
        height: 146px;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        padding-left: 48px;

        @media screen and (max-width: 1600px) {
          padding-left: 32px;
        }
        @media screen and (max-width: 1366px) {
          padding-left: 32px;
        }
      }
    }

    img.floating {
      position: absolute;
      left: calc(50% - 20px);
      top: calc(50% - 24px);
      @media screen and (max-width: 1600px) {
        left: calc(50% - 19px);
        top: calc(50% - 23px);
      }
      @media screen and (max-width: 1366px) {
        left: calc(50% - 19px);
        top: calc(50% - 23px);
      }
      @media screen and (max-width: 1023px) {
        left: calc(50% - 17px);
        top: calc(50% - 23px);
      }
      @media screen and (max-width: 600px) {
        left: calc(50% - 16px);
        top: calc(50% - 22px);
      }
    }
  }
}

.limit-bar {
  margin-top: 30px;
  display: flex;
  align-items: center;

  &-inner {
    margin: 0 20px;
    flex: 1;
    position: relative;
    height: 8px;
    border-radius: 666px;
    background-color: transparentize(#000000, 1-0.2);
  }

  &-track {
    border-radius: 666px;
    position: absolute;
    height: 100%;
    width: 0%;
    background-color: #000000;
    transition: all 0.4s;

    span {
      position: absolute;
      right: 0;
      margin-top: -30px;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: italic;

      @media screen and (max-width: 1600px) {
        font-size: 17px;
      }
    }
  }

  &.mini {
    margin-top: 0;
    height: 23px;
    font-size: 12px;
    flex-direction: column;
    align-items: self-start;
    .values {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    label {
      font-family: "Poppins", sans-serif;
      font-weight: normal;
    }
    .limit-bar-inner {
      height: 4px;
      margin: 0;
      width: 100%;
    }

    .limit-bar-track {
      span {
        font-weight: normal;
        font-size: 12px;
        font-style: normal;
        margin-top: 6px;
        right: -20px;
      }
    }
  }
}

.connect-container {
  position: relative;
}

.connect-wallet {
  top: 60px;
  left: 41%;
  position: absolute;
  width: 202.5px * 0.9;
  height: 162px * 0.9;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
  z-index: 1000;

  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  @media (max-width: 600px){
    left: -24px;
  }
  h3 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  img {
    width: 122px;
  }

  i {
    position: absolute;
    top: -8px;
    left: 50%;
    margin-left: -8px;
    width: 16px;
    height: 8px;
    overflow: hidden;
  }

  i::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    left: 50%;
    top: 0;
    transform: translate(-50%, 50%) rotate(45deg);
    background-color: #ffffff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  }
}

.nowrap {
  white-space: nowrap;
}
