@font-face {
    font-family: "NeueHaasGroteskDisp Pro Md"; 
    src: url("/fonts/NeueHaasGroteskDisp Pro Md.eot"); 
    src: url("/fonts/NeueHaasGroteskDisp Pro Md.eot?#iefix") 
    format("embedded-opentype"), 
    url("/app/fonts/NeueHaasGroteskDisp Pro Md.woff2") 
    format("woff2"), url("/fonts/NeueHaasGroteskDisp Pro Md.woff") 
    format("woff"), url("/fonts/NeueHaasGroteskDisp Pro Md.ttf") 
    format("truetype"), url("//db.onlinewebfonts.com/t/c891e94039740e4a24a9f53324fd91be.svg#NeueHaasGroteskDisp Pro Md") 
    format("svg"); }
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;800&display=swap');

$titleFont: "NeueHaasGroteskDisp Pro Md";
$bodyFont : "Poppins";
