@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dropin {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes dropout {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(-30px);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  20% {
    transform: scale(1.1);
    opacity: 0;
  }

  31% {
    transform: scale(0.7);
    opacity: 0;
  }
  32% {
    opacity: 0;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }

}

@keyframes frag {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  20% {
    transform: translate(7px, -7px);
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  31% {
    transform: translate(-10px, 10px);
  }
  32% {
    opacity: 0;
  }

  50% {
    transform: translate(0,0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }

}

.fade-in{
  animation: fadein 0.5s forwards;
  animation-timing-function: ease-in-out;
}