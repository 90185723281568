.even {
  display: flex;
  & > * {
    flex: 1;
    padding-right: 5px;
    &:last-child{
      padding-right: 0;
    }
  }
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
}


.split {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * {
  }
}

.ln {
  background-color: #979797;
  display: block;
  height: 2px;

  &.light {
    background-color: transparentize(#0b0412, 1-0.2);
  }
}

#root {
  display: flex;
  min-height: 100%;
}

.container {
  width: 95%;
  margin: 0 auto;
  max-width: 1260px;
  transition: all 0.3s;

  @media screen and (max-width: 1600px) {
    max-width: 1160px;
  }

  @media screen and (max-width: 1366px) {
    max-width: 1036px;
  }

}

.title-bar {
  height: 117px;

  @media screen and (max-width: 1600px) {
    height: 86px;
  }

  h1 {
    font-size: 28px;
    font-weight: normal;
    letter-spacing: 1.4px;
  }
}
