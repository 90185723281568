@import "fonts";
@import "colors";

.migrate {

  display: flex;
  flex-direction: column;
  align-items: center;

  .migration-drawing {
    margin: 30px 0;
    img {
      max-width: 438px;
    }
  }

  h2 {
    font-family: $titleFont, sans-serif;
    font-size: 32px;
    font-weight: normal;
    letter-spacing: 1.6px;
  }
  @media (max-width: 1023px){
    width: 90%;
    min-height: 90%!important;
    padding: 0 5%;
    text-align: center;
    h2{
      font-size:26px;
    }
  }
  @media (max-width: 600px){
    h2{
      font-size: 20px;
    }
    .migration-drawing {
      margin: 20px 0;
      img {
        max-width: 100%;
      }
    }
  }
}
