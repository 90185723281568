@import "colors";
@import "fonts";

.modal {

  &-container {
    opacity: 0;
    position: fixed;
    top: -200vh;
    z-index: 100000;
    background-color: rgba(255,255,255,0.9);
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s;
    align-items: center;
    justify-content: center;

    &.active {
      top: 0;
      display: flex;
      opacity: 1;
    }
  }

  &-dialog {
    width: 815px;
    height: 600px;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2), 0 0 10px 0 rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 1023px){
      width:80%;
      min-height: 80%;
    }
    @media screen and (max-width: 600px){
      width: 98%;
      min-height: 90%;
    }
  }

  &-close-btn {
    display: flex;
    justify-content: flex-end;
    margin: 30px;
    opacity: 0.7;
    transition: all 0.2s;

    &:hover {
      opacity: 1;
    }

    img {
      width: 24px;
      cursor: pointer;
    }
  }
}
