@import "fonts";

.connect-button {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 0 25px;
  border: 2px solid transparentize(#25c068, 0);
  color: #25c068;
  margin: 0;
  height: 40px;
  font-size: 14px;
  transition: all 0.3s;
  font-family: $titleFont;
  letter-spacing: 0.6px;

  text-transform: uppercase;

  @media screen and (max-width: 1600px) {
    height: 34px;
    font-size: 12px;
  }

  .btn-inner {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;

    max-width: 200px;
    @media screen and (max-width: 600px){
      max-width: 100px;
    }
  }

  &:hover {
    border-color: #979797;
  }

  &.active {
    border-color: #000000;
    background-color: #000000;
    color: #ffffff;
  }
}

.terms-wrapper {
  display: flex;
  // max-width: 200px;
  height: 40px;
  @media screen and (max-width: 600px){
    flex-direction: column-reverse;
    height: 120px;
    justify-content: space-between;
    max-width: 140px;

  }
  .term-text-btn {
    font-size: 11px;
    line-height: 14px;
    color: #647686;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    max-width: 200px;

    padding: 0 10px;
    background-color: #d6f2e3;
    border-radius: 6px;
    @media screen and (max-width: 600px){
      padding: 10px;
    }
  }
}

.view-button {
  a {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    color: #25c068;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.8px;

    span {
      margin-right: 6px;
    }
  }
}

.migration-btn {
  cursor: pointer;
  border: 0;
  border-radius: 6px;
  width: 200px;
  height: 59px;
  background-color: $btnBg;
  color: $btnColor;
  letter-spacing: 0.83px;
  font-size: 16px;
  font-family: $titleFont, sans-serif;
  text-transform: uppercase;
  transition: all 0.2s;

  &:hover {
    background-color: darken($btnBg, 20);
    color: darken($btnColor, 20);
  }
}

.currency-action-button {
  cursor: pointer;
  border: 0;
  border-radius: 6px;
  width: 110px;
  height: 45px;
  background-color: $btnBg;
  color: $btnColor;
  letter-spacing: 0.7px;
  font-size: 14px;
  font-family: $titleFont, sans-serif;
  text-transform: uppercase;
  margin-bottom: 23px;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1600px) {
    height: 38px;
    font-size: 12px;
    margin-bottom: 20px;
  }
}

.currency-input-button {
  cursor: pointer;
  border: 0;
  border-radius: 6px;
  width: 110px;
  height: 45px;
  background-color: #d6f2e3;
  color: #25c068;
  letter-spacing: 0.7px;
  font-size: 14px;
  font-family: $titleFont, sans-serif;
  text-transform: uppercase;
  margin-left: 10px;

  transition: all 0.3s;

  &.disabled {
    color: #aaaaaa;
    background-color: #dddddd;
  }

  @media screen and (max-width: 1600px) {
    height: 38px;
    font-size: 12px;
    margin-bottom: 20px;
  }
}

x{
  color: rgb(151, 151, 151)
}