@import "colors";
@import "fonts";

.menu-toggle{
  position: absolute;
  right: -60px;
  top: 15px;
  width: 50px;
  height: 50px;
  display: none;
  @media screen and (max-width: 1023px) {
    display: initial;
  }
}

.sidebar {
  transition: all 0.3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  padding: 47px 0;
  width: 264px;
  background-color: $sidebarBg;
  color: $sidebarColor;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.24), 0 0 7px 0 rgba(0, 0, 0, 0.12);
  z-index: 20000;
  display: flex;
  flex-direction: column;
  align-self: stretch;

  @media screen and (max-width: 1600px) {
    width: 227px;
    padding: 27px 0;
  }

  @media screen and (max-width: 1023px) {
    transform: translateX(-227px);
    &.open{
      transform: translateX(0);
    }
  }

  &-content {
    flex: 1;

    .product {
      padding: 0 20px;
      display: flex;
      height: 25px;
      align-items: center;
      margin-bottom: 30px;
      border-left: 7px transparent solid;

      p {
        font-size: 16px;
        font-weight: 500;
        @media screen and (max-width:1600px){
          font-size: 14.7px;
        }
        @media screen and (max-width:1366px){
          font-size: 14px;
        }
        
      }
      img {
        width: 90px;
        height: 20px;
        object-fit: contain;
        object-position: 0 50%;
      }

      &.selected {
        border-left-color: #25c068;
      }

      small {
        font-family: $titleFont;
        letter-spacing: 0.46px;
        font-size: 13px;
        margin-left: 10px;
        font-weight: normal;

        @media screen and (max-width: 1600px) {
          font-size: 12px;
          margin-left: 6px;
        }
      }
    }
  }

  .link-accesible {
    cursor: pointer;
  }

  .cdp-convert-info {
    animation: fadein 0.3s forwards;
  }

  .cdp-convert {
    margin: 0 20px;
    font-size: 14px;

    p {
      margin: 20px 0;
      font-size: 14px;
      line-height: 1.29;
      letter-spacing: 0.88px;

      @media screen and (max-width: 1600px) {
        font-size: 12px;
      }
    }
  }

  &-footer {
    justify-self: flex-end;
    text-align: center;

    h3 {
      font-weight: 300;
      font-size: 16px;

      @media screen and (max-width: 1600px) {
        font-size: 14px;
      }
    }

    .social-icons {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
    }

    p.credits {
      font-size: 14px;

      @media screen and (max-width: 1600px) {
        font-size: 12px;
      }
    }
  }

  .logo {
    margin: 0 auto 38px auto;
    display: flex;

    @media screen and (max-width: 1600px) {
      width: 152px;
      margin: 0 auto;
    }
  }

  .migrate-btn {
    height: 45px;
    border-radius: 3.2px;
    background-color: $sidebarBtnBg;
    color: $sidebarBtnColor;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    letter-spacing: 0.9px;
    //text-transform: uppercase;

    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: $sidebarBtnHoverBg;
      color: $sidebarBtnHoverColor;
    }

    &.disabled {
      cursor: default;
      &:hover {
        background-color: $sidebarBtnBg;
        color: $sidebarBtnColor;
      }
    }
  }

  .ln {
    margin: 30px 20px;
    background-color: $sidebarSeparatorColor;
    height: 1px;
  }

  p {
    font-size: 16px;
    letter-spacing: 1px;
    // color: #ffffff;
  }
  .menu-item {
    color: #ffffff;
    line-height: 30px;
    letter-spacing: 0.8px;
    cursor: pointer;
  }

  small {
    font-family: "Poppins", sans-serif;
    font-size: 13px;
  }
}

// .sidebar-footer {
//   margin-bottom: 40px;
// }
