$pageBg: #ffffff;

$jumboBg: #f2fbf6;
$jumboBgStart: rgba(37, 192, 104, 0);
$jumboBgEnd: rgba(12, 233, 108, 0.45);

$btnColor: #17ab57;
$btnBg: #d6f2e3;
$sidebarBg: #0b0412;
$sidebarColor: #647686;
$sidebarSeparatorColor: transparentize(#647686, 0.7);

$sidebarBtnBg: #fbb936;
$sidebarBtnColor: #734a00;
$sidebarBtnHoverBg: #ffffff;
$sidebarBtnHoverColor: #333333;

$sidebarSocialBtnBg: #121b23;

$panelBg: #ffffff;
$panelColor: #0b0412;
$panelSecondaryColor: transparentize(#0b0412, 0.5);
$panelSeparatorColor: transparentize(#979797, 0.8);

$statsColor: #ffffff;
$statsTitleColor: #ffffff;

$inputBorder: #979797;
